import React from 'react';
import Rehype from 'rehype-react';
import { Link } from 'gatsby';

export default ({ data }) => {
  const guide = data.markdownRemark;

  function h1(props) {
    return (
      <h1 className="text-2xl font-normal pt-8" {...props}>
        {props.children}
      </h1>
    );
  }

  function h2(props) {
    return (
      <h2 className="text-2xl font-normal py-4" {...props}>
        {props.children}
      </h2>
    );
  }

  function h3(props) {
    return (
      <h3 className="text-lg font-normal py-4" {...props}>
        {props.children}
      </h3>
    );
  }

  function p(props) {
    return (
      <p className="font-normal text-black py-2 relative" {...props}>
        {props.children}
      </p>
    );
  }

  function a(props) {
    return <a {...props} />;
  }

  function th(props) {
    return <th {...props} className="border w-1/3 p-2 text-left" />;
  }

  function tr(props) {
    return <tr {...props} className="border" />;
  }

  function td(props) {
    return <td {...props} className="border w-1/3 p-2" />;
  }

  function table(props) {
    return <table {...props} className="w-full" />;
  }

  const renderAst = new Rehype({
    createElement: React.createElement,
    components: {
      h1,
      h2,
      h3,
      p,
      a,
      table,
      tr,
      td,
      th
    }
  }).Compiler;

  return (
    <div>
      <div className="container mx-auto">{renderAst(guide.htmlAst)}</div>
    </div>
  );
};

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      htmlAst
    }
  }
`;
